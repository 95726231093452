import React from 'react';
import ReactDOM from 'react-dom';
import { NavLink } from 'react-router-dom';

import Button from 'components/buttons/Button/Button';

import './MainMenu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faHouse,
	faUser,
	faComment,
	faGears,
	faRightFromBracket,
} from '@fortawesome/free-solid-svg-icons';

function MainMenu({ closeMenu }) {
	return (
		<div className="row g-2 justify-content-center text-center">
			<div className="col col-sm-3 col-md-3 col-lg">
				<NavLink
					to="/"
					className={({ isActive, isPending, isTransitioning }) =>
						[
							'w-100 h-100 d-block',
							isPending ? 'pending' : '',
							isActive ? 'active' : '',
							isTransitioning ? 'transitioning' : '',
						].join(' ')
					}>
					{({ isActive }) => (
						<Button
							size="small"
							width="100"
							height="100"
							labelDirection="horizontal"
							label={
								<>
									<FontAwesomeIcon
										className="mb-1"
										icon={faHouse}
										size="xl"
									/>
									<br />
									<span>Start</span>
								</>
							}
							clickTrigger={closeMenu}
							type="white"
							isLink={isActive}
						/>
					)}
				</NavLink>
			</div>

			<div className="col col-sm-3 col-md-3 col-lg">
				<NavLink
					to="/profile"
					className={({ isActive }) => (isActive ? 'active' : '')}>
					{({ isActive }) => (
						<Button
							size="small"
							width="100"
							height="100"
							labelDirection="horizontal"
							label={
								<>
									<FontAwesomeIcon
										className="mb-1"
										icon={faUser}
										size="xl"
									/>
									<br />
									<span>Profil</span>
								</>
							}
							clickTrigger={closeMenu}
							type="white"
							isLink={isActive}
						/>
					)}
				</NavLink>
			</div>

			<div className="col col-sm-3 col-md-3 col-lg">
				<NavLink
					to="/bubbles"
					className={({ isActive }) => (isActive ? 'active' : '')}>
					{({ isActive }) => (
						<Button
							size="small"
							width="100"
							height="100"
							labelDirection="horizontal"
							label={
								<>
									<FontAwesomeIcon
										className="mb-1"
										icon={faComment}
										size="xl"
									/>
									<br />
									<span>Bubbles</span>
								</>
							}
							clickTrigger={closeMenu}
							type="white"
							isLink={isActive}
						/>
					)}
				</NavLink>
			</div>

			<div className="col col-sm-3 col-md-3 col-lg">
				<NavLink
					to="/settings"
					className={({ isActive }) => (isActive ? 'active' : '')}>
					{({ isActive }) => (
						<Button
							size="small"
							width="100"
							height="100"
							labelDirection="horizontal"
							label={
								<>
									<FontAwesomeIcon
										className="mb-1"
										icon={faGears}
										size="xl"
									/>
									<br />
									<span>Einstellungen</span>
								</>
							}
							clickTrigger={closeMenu}
							type="secondary"
							isLink={isActive}
						/>
					)}
				</NavLink>
			</div>
			<div className="col col-sm-3 col-md-3 col-lg">
				<NavLink
					to="/logout"
					className={({ isActive }) => (isActive ? 'active' : '')}>
					{({ isActive }) => (
						<Button
							size="small"
							width="100"
							height="100"
							labelDirection="horizontal"
							label={
								<>
									<FontAwesomeIcon
										className="mb-1"
										icon={faRightFromBracket}
										size="xl"
									/>
									<br />
									<span>Abmelden</span>
								</>
							}
							clickTrigger={closeMenu}
							type="secondary"
							isLink={isActive}
						/>
					)}
				</NavLink>
			</div>
		</div>
	);
}

export default MainMenu;
