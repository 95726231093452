import React, { useRef, useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import 'assets/bootstrap/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import 'assets/css/core.css';
import 'App.css';
import 'assets/css/index.css';

import { PageTitleContext } from 'contexts/PageTitleContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faFeatherPointed } from '@fortawesome/free-solid-svg-icons';

import Docs from 'pages/Docs';
import MainMenu from 'components/navs/MainMenu/MainMenu';
import NewPostModal from 'components/NewPostModal/NewPostModal';
import Page from 'components/layout/Page/Page';
import Feed from 'pages/Feed/Feed';
import Search from 'pages/Search';
import Profile from 'pages/Profile/Profile';
import Post from 'pages/Post';
import Comment from 'pages/Comment';
import Welcome from 'pages/Welcome/Welcome';
import Login from 'pages/Login/Login';
import Logout from 'pages/Logout/Logout';
import Register from 'pages/Register/Register';
import Verify from 'pages/Verify/Verify';
import ResendCode from 'pages/ResendCode/ResendCode';
import ProtectedRoute from 'components/ProtectedRoute';
import { getUserToken } from './API';
import Bubbles from 'pages/Bubbles/Bubbles';
import Bubble from 'pages/Bubble/Bubble';
import Settings from 'pages/Settings/Settings';



const AppContent = () => {
    const pageRef = useRef();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [fgContent, setFgContent] = useState(null);
    const [fgHeight, setFgHeight] = useState('auto');
    const [pageTitle, setPageTitle] = useState('Rising Arrow');

    useEffect(() => {
        const token = getUserToken();
        setIsAuthenticated(!!token);
    }, []);

    const handleOpenMenu = (component, height = 'auto') => {
        setFgContent(component);
        setFgHeight(height === 'auto' ? height : `calc(${height}vh - 1.5rem)`);
        if (pageRef.current) {
            pageRef.current.openMenu();
        }
    };

    return (
        <PageTitleContext.Provider value={{ pageTitle, setPageTitle }}>
            <div className="app-container border-gray-1" data-bs-theme="dark">
                <div className="w-100">
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <Page
                                    ref={pageRef}
                                    fgHeight={fgHeight}
                                    fg={fgContent}
                                    onCloseMenu={() => setFgContent(null)}
                                    bg={<ProtectedRoute element={Feed} />}
                                />
                            }
                        />
                        <Route
                            path="/profile"
                            element={
                                <Page
                                    ref={pageRef}
                                    fgHeight={fgHeight}
                                    fg={fgContent}
                                    onCloseMenu={() => setFgContent(null)}
                                    bg={<ProtectedRoute element={Profile} />}
                                />
                            }
                        />
                        <Route
                            path="/bubbles"
                            element={
                                <Page
                                    ref={pageRef}
                                    fgHeight={fgHeight}
                                    fg={fgContent}
                                    onCloseMenu={() => setFgContent(null)}
                                    bg={<ProtectedRoute element={Bubbles} />}
                                />
                            }
                        />

                        <Route 
                            path="/bubble/:id"
                            element={
                                <Page
                                    ref={pageRef}
                                    fgHeight={fgHeight}
                                    fg={fgContent}
                                    onCloseMenu={() => setFgContent(null)}
                                    bg={<ProtectedRoute element={Bubble} />}
                                />
                            }
                        />

                        <Route path="/welcome" element={<Welcome />} />
                        <Route
                            path="/login"
                            element={
                                <Login
                                    onLogin={() => setIsAuthenticated(true)}
                                />
                            }
                        />
                        <Route
                            path="/logout"
                            element={
                                <Page
                                    ref={pageRef}
                                    fgHeight={fgHeight}
                                    fg={fgContent}
                                    onCloseMenu={() => setFgContent(null)}
                                    bg={
                                        <Logout
                                            onLogout={() => setIsAuthenticated(false)}
                                        />
                                    }
                                />
                            }
                        />
                        <Route path="/register" element={<Register />} />
                        <Route
                            path="/verify"
                            element={
                                <Verify
                                    onVerify={() => setIsAuthenticated(true)}
                                />
                            }
                        />
                        <Route path="/resend" element={<ResendCode />} />


                        <Route 
                            path="/settings"
                            element={
                                <Page
                                    ref={pageRef}
                                    fgHeight={fgHeight}
                                    fg={fgContent}
                                    onCloseMenu={() => setFgContent(null)}
                                    bg={<ProtectedRoute element={Settings} />}
                                />
                            }
                        />

                    </Routes>

                    {isAuthenticated && (
                        <div className="d-flex">
                            <button
                                className="ra-btn icon-button main-menu-button left"
                                onClick={() => handleOpenMenu(<NewPostModal />, '100%')}
                            >
                                <FontAwesomeIcon icon={faFeatherPointed} size="lg" />
                            </button>
                            <div className="nav-indicator-container">
                                <div className="nav-indicator crop-text-2">{pageTitle}</div>
                            </div>
                            <button
                                className="ra-btn icon-button main-menu-button"
                                onClick={() => handleOpenMenu(<MainMenu />, 'auto')}
                            >
                                <FontAwesomeIcon icon={faBars} size="lg" />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </PageTitleContext.Provider>
    );
};

export default AppContent;
