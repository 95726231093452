import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { get } from 'API';

import './Bubble.css';

// Hooks
import usePageTitle from 'hooks/usePageTitle';

// Components
import FeedItem from 'components/feed/FeedItemNew/FeedItemNew';

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faHeart,
	faComments,
	faPeopleGroup,
	faGears,
	faFeatherPointed,
} from '@fortawesome/free-solid-svg-icons';

function Bubble() {
	const { id } = useParams();
	const endpoint = `bubbles/${id}`;
	const [bubble, setBubble] = useState(null);

	useEffect(() => {
		if (id) {
			get(endpoint)
				.then((data) => setBubble(data))
				.catch((error) =>
					console.error('Fehler beim Laden der Daten:', error)
				);
		}
	}, [id, endpoint]);

	// Pass bubble.name to usePageTitle
	usePageTitle({ name: bubble?.name || 'Lädt...' });

	if (!bubble) {
		return <div>Lädt...</div>;
	}

	return (
		<div className="bubble">
			<div className="bubble-header-container">
				<div className="bubble-header mb-3">
					<h1 className="h3 crop-text-2">{bubble.name}</h1>
					<div className="background">
						<img
							src={bubble.media?.medium?.[0]}
							alt={bubble.name}
						/>
					</div>
				</div>
				<div className="bubble-interactions row flex-nowrap gx-0">
					<div className="col">
						<div className="bubble-interaction">
							<FontAwesomeIcon
								className="icon mb-1"
								icon={faHeart}
								size="xl"
							/>
							<div className="label crop-text-1">
								{(
									bubble.likes_count || 123022
								).toLocaleString()}
							</div>
						</div>
					</div>
					<div className="col">
						<div className="bubble-interaction">
							<FontAwesomeIcon
								className="icon mb-1"
								icon={faComments}
								size="xl"
							/>
							<div className="label crop-text-1">
								{(
									bubble.contents_count || 3321
								).toLocaleString()}
							</div>
						</div>
					</div>
					<div className="col">
						<div className="bubble-interaction">
							<FontAwesomeIcon
								className="icon mb-1"
								icon={faPeopleGroup}
								size="xl"
							/>
							<div className="label crop-text-1">Mitglieder</div>
						</div>
					</div>
					<div className="col">
						<div className="bubble-interaction">
							<FontAwesomeIcon
								className="icon mb-1"
								icon={faGears}
								size="xl"
							/>
							<div className="label crop-text-1">
								Einstellungen
							</div>
						</div>
					</div>
					<div className="col">
						<div className="bubble-interaction">
							<FontAwesomeIcon
								className="icon mb-1"
								icon={faFeatherPointed}
								size="xl"
							/>
							<div className="label crop-text-1">
								Neuer Inhalt
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="bubble-contents">
				{bubble.contents?.map((item, index) => (
					<FeedItem
						key={item._id}
						item={item}
					/>
				))}
			</div>
		</div>
	);
}

export default Bubble;
