import React, { useContext, useState } from "react";
import Button from 'components/buttons/Button/Button';
import { UserContext } from 'contexts/UserContext';
import { save, get } from 'API';
import DOMPurify from 'dompurify';

import './NewPostModal.css';

function NewPostModal({ closeMenu }) {
    const { user } = useContext(UserContext);
    const [content, setContent] = useState('');
    const [media, setMedia] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);

    const handleContentChange = (e) => {
        setContent(e.target.value);
    };

    const handleMediaChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setMedia(file);
        }
    };

    const handleSave = async () => {
        setLoading(true);
        setErrorMsg(null);
        setSuccessMsg(null);

        try {
            const sanitizedContent = DOMPurify.sanitize(content);
            const postData = {
                type: 'post',
                content: sanitizedContent
            };

            // Save the post content
            const response = await save('contents', postData);
            if (response && response._id) {
                // If there's media, upload it separately
                if (media) {
                    const formData = new FormData();
                    formData.append('file', media);

                    try {
                        await save(`contents/${response._id}/media`, formData, null, 'POST');
                    } catch (mediaError) {
                        console.error('Error uploading media:', mediaError);
                        setErrorMsg('Post wurde erstellt, aber das Bild konnte nicht hochgeladen werden!');
                        setLoading(false);
                        return;
                    }
                }

                // Fetch the updated post data
                // const updatedPost = await get(`contents/${response._id}`);
                setContent('');
                setMedia(null);
                setSuccessMsg('Post erfolgreich erstellt!');
                closeMenu();
                // addNewPost(updatedPost);

            }
        } catch (error) {
            setErrorMsg('Post konnte nicht abgeschickt werden!');
            console.error('Error saving post:', error);
        } finally {
            setLoading(false);
        }
    };


    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
    };

    return (
        <div className="d-flex flex-column p-3">
            <div className="d-flex align-items-center mb-3">
                <div style={{ width: '40px', marginRight: '10px' }}>
                    <img
                        src={user.media?.thumbnail?.[1]}
                        alt=""
                        className="img-fluid rounded-circle"
                    />
                </div>
                <div>{user.name}</div>
            </div>
            <textarea
                className="form-control mb-3"
                value={content}
                onChange={handleContentChange}
                placeholder="Was steht an?"
                rows={4}
            />
            <div className="d-flex justify-content-between align-items-center">
                <input
                    type="file"
                    accept="image/*,video/*"
                    capture="camera"
                    onChange={handleMediaChange}
                    className="d-none"
                    id="fileInput"
                />
                <button 
                    type="button" 
                    className="btn btn-secondary" 
                    onClick={triggerFileInput}
                >
                    Datei auswählen
                </button>
                <Button 
                    label="Post speichern" 
                    type="primary"
                    size="small"
                    ghost={true} 
                    errorMsg={errorMsg}
                    successMsg={successMsg}
                    clickTrigger={() => {}}
                    clickResponse={handleSave}
                    disabled={loading || !content.trim()}
                />
            </div>
        </div>

    );
}

export default NewPostModal;

