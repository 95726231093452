import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import Button from 'components/buttons/Button/Button';
import { clearUserData } from 'API';

import './Logout.css';

//Hooks
import usePageTitle from 'hooks/usePageTitle';

function Logout({ onLogout }) {
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate();

    usePageTitle("Abmelden");

    const handleLogout = () => {
        clearUserData();
        onLogout();
        const successMessage = "Du wurdest erfolgreich abgemeldet.";
        setSuccess(successMessage); // Optional: update local state if needed
        return Promise.resolve(successMessage);
    };

    const handleCallBack = () => {
        setSuccess(null);
        navigate('/welcome');
    };

    return (
        <div className="welcome">
            <div className="menu-container">
                <div className="menu">
                    <div className="row mb-5">
                        <div className="col-12">
                            <h1>Abmelden</h1>
                            <p>Möchtest du dich wirklich abmelden?</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Button 
                                label="Abmelden" 
                                type="primary" 
                                width="100"
                                ghost={true}
                                successMsg={success}
                                clickResponse={handleLogout}
                                callback={handleCallBack}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Logout;