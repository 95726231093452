import React, { useState } from "react";
import moment from "moment";
import "moment/locale/de";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as fasFaHeart, faComment as fasFaComment } from "@fortawesome/free-solid-svg-icons";

import "./FeedItem.css";

function FeedItem({ item }) {
    const [fullscreenImage, setFullscreenImage] = useState(null);
    const [startY, setStartY] = useState(null); // Track the starting Y position of the touch

    const handleTouchStart = (e) => {
        setStartY(e.touches[0].clientY); // Save the initial touch Y position
    };

    const handleTouchMove = (e) => {
        if (!startY) return;

        const currentY = e.touches[0].clientY;
        const deltaY = currentY - startY;

        // If the swipe is upward (negative deltaY), close the modal
        if (deltaY < -50) { // Adjust the threshold (-50) as needed
            const modalElement = document.getElementById("imagePreviewModal");
            // Remove Bootstrap classes to close the modal
            modalElement.classList.remove("show");
            modalElement.style.display = "none";

            // Remove the backdrop if necessary
            const backdrop = document.querySelector(".modal-backdrop");
            if (backdrop) backdrop.remove();

            document.body.classList.remove("modal-open");
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }
    };

    return (
        <div className="feed-item">
            <div className="bg">
                <img src={item.media?.medium?.[0]} alt="" />
            </div>
            <div className="content">
                <div className="d-flex">
                    <div className="left">
                        <div className="ratio ratio-1x1 mb-3">
                            <img src={item.user?.media?.thumbnail?.[1]} alt="" />
                        </div>
                        <div className="mb-3">
                            <FontAwesomeIcon className="icon" icon={fasFaHeart} size="1x" />
                            <br />
                            <span className="ml-1">{item.likes_count || 0}</span>
                        </div>
                        <div>
                            <FontAwesomeIcon className="icon" icon={fasFaComment} size="1x" />
                            <br />
                            <span className="ml-1">{item.comments_count || 0}</span>
                        </div>
                    </div>
                    <div className="right">
                        <div className="d-flex mb-2 mt-1">
                            <div className="username">{item.user?.name}</div>
                            <div className="time-since">{moment(item.created_at).fromNow()}</div>
                        </div>
                        <div>
                            <p>{item.content}</p>
                        </div>
                        <div>
                            <div className="media">
                                {item.media?.media_type === "video" && (
                                    <video controls poster={item.media?.large?.[4]}>
                                        <source src={item.media?.video} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                )}
                                {item.media?.media_type === "image" && (
                                    <img
                                        src={item.media?.medium?.[0]}
                                        alt=""
                                        data-bs-toggle="modal"
                                        data-bs-target="#imagePreviewModal"
                                        onClick={() => setFullscreenImage(item.media?.large?.[0])}
                                    />
                                )}
                                {!item.media?.media_type && (
                                    <img
                                        src={item.media?.medium?.[0]}
                                        alt=""
                                        data-bs-toggle="modal"
                                        data-bs-target="#imagePreviewModal"
                                        onClick={() => setFullscreenImage(item.media.large?.[4])}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Bootstrap Modal */}
            <div
                className="modal fade"
                id="imagePreviewModal"
                tabIndex="-1"
                aria-labelledby="imagePreviewModalLabel"
                aria-hidden="true"
                onTouchStart={handleTouchStart} // Start tracking touch
                onTouchMove={handleTouchMove} // Handle touch movement
            >
                <div className="modal-dialog modal-dialog-centered modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="imagePreviewModalLabel">Bildvorschau</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center">
                            {fullscreenImage && <img src={fullscreenImage} alt="Fullscreen preview" className="img-fluid" />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FeedItem;
