import React, { useState, useEffect } from 'react';
import { get } from 'API';
import FeedItem from "components/feed/FeedItemNew/FeedItemNew";
import NavTabs from "components/navs/NavTabs";
import NewPost from "components/NewPostModal/NewPostModal";

//Hooks
import usePageTitle from 'hooks/usePageTitle';

function Feed() {
    const [resultData, setResultData] = useState([]);
    const [newPosts, setNewPosts] = useState([]);
    const endpoint = 'contents';

    usePageTitle();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await get(endpoint);
                setResultData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [endpoint]);

    const handleShowNewPosts = () => {
        setResultData(prevData => [...newPosts, ...prevData]);
        setNewPosts([]);
    };

    const reloadItem = async (itemId) => {
        try {
            const updatedItem = await get(`${endpoint}/${itemId}`);
            setResultData(prevData =>
                prevData.map(item => (item._id === updatedItem._id ? updatedItem : item))
            );
        } catch (error) {
            console.error('Error reloading item:', error);
        }
    };

    const addNewPost = (newPost) => {
        setResultData(prevData => [newPost, ...prevData]);
    };

    return (
        <>
            {newPosts.length > 0 && (
                <button className="btn btn-primary border-0 border-width-2 border-bottom border-gray-1 w-100" onClick={handleShowNewPosts}>
                    ({newPosts.length} neu) Tippe um anzuzeigen
                </button>
            )}
            <div className="px-3">
                {resultData.map((item, index) => (
                    <FeedItem key={item._id} item={item} reloadItem={reloadItem} />
                ))}
            </div>
        </>
    );
}

export default Feed;

