import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { get } from 'API';

import './Settings.css';

//Hooks
import usePageTitle from 'hooks/usePageTitle';

//Components


//Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {} from '@fortawesome/free-solid-svg-icons';

function Settings() {

    usePageTitle("Einstellungen")

    return (
        <div className="settings">

            
        </div>
    );
}

export default Settings;
