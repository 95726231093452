import React, { forwardRef, useState, useImperativeHandle } from 'react';
import { useSpring, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import './Page.css';

const Page = forwardRef(({ fg, fgHeight, bg, onCloseMenu }, ref) => {
	const [isActive, setIsActive] = useState(false);

	useImperativeHandle(ref, () => ({
		openMenu,
		closeMenu, // Optional: expose closeMenu if needed
	}));

	// Foreground spring for vertical translation
	const [fgSpring, setFgSpring] = useSpring(() => ({
		y: window.innerHeight, // Start off-screen
		config: { tension: 250, friction: 30 },
	}));

	const openMenu = () => {
		setIsActive(true);
		setFgSpring({ y: 0 });
	};

	const closeMenu = () => {
		setFgSpring({
			y: window.innerHeight,
			onRest: () => {
				setIsActive(false);
				if (onCloseMenu) {
					onCloseMenu();
				}
			},
		});
	};

	const bind = useDrag(
		({ last, movement: [_, my] }) => {
			if (my < 0) return; // Prevent upward dragging

			if (last) {
				// On release
				if (my > 100) {
					// Close the menu if dragged down sufficiently
					closeMenu();
				} else {
					// Snap back to open position
					setFgSpring({ y: 0 });
				}
			} else {
				// While dragging
				setFgSpring({ y: my, immediate: true });
			}
		},
		{ axis: 'y', pointer: { touch: true } }
	);

	return (
		<div
			className="page-wrapper">
			<animated.div
				className={`page bg ${isActive ? 'pe-none' : ''}`}
				style={{
					transform: fgSpring.y.to((y) => {
						const scale = 0.95 + (0.05 * y) / window.innerHeight;
						return `scale(${scale})`;
					}),
					filter: fgSpring.y.to((y) => {
						const blur =
							((window.innerHeight - y) / window.innerHeight) * 5;
						const brightness = 0.7 + (y / window.innerHeight) * 0.3;
						return `blur(${blur}px) brightness(${brightness})`;
					}),
				}}>
				{bg}
			</animated.div>
			{isActive && (
				<>
					<div
						className="overlay"
						onClick={closeMenu}></div>
					<animated.div
						className="page fg"
						style={{
							transform: fgSpring.y.to(
								(y) => `translateY(${y}px)`
							),
							height: fgHeight,
						}}
						{...bind()}>
						{React.cloneElement(fg, { closeMenu })}
					</animated.div>
				</>
			)}
		</div>
	);
});

export default Page;
