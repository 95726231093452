import { useLocation } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import { match } from "path-to-regexp";
import { PageTitleContext } from 'contexts/PageTitleContext';

const titles = {
    "/": "Startseite",
    "/profile": "{display_name}",
    "/bubbles": "Bubbles",
    "/bubble/:id": "{name}",
    "/welcome": "Willkommen",
    "/login": "Anmelden",
    "/logout": "Abmelden",
    "/register": "Registrieren",
    "/verify": "Verifizieren",
    "/resend": "Code erneut senden",
    "/settings": "Einstellungen",
};

const matchPathToTitle = (pathname) => {
    for (const route in titles) {
        const matcher = match(route, { decode: decodeURIComponent });
        const matched = matcher(pathname);
        if (matched) {
            return { route, params: matched.params };
        }
    }
    return null;
};

const usePageTitle = (dynamicData = {}) => {
    const location = useLocation();
    const { setPageTitle } = useContext(PageTitleContext); // Access setPageTitle from context

    useEffect(() => {
        const path = location.pathname;
        const matchedRoute = matchPathToTitle(path);

        if (matchedRoute) {
            const { route, params } = matchedRoute;

            let pageTitle = titles[route] || 'Default Title';

            // Replace placeholders with dynamic data
            Object.keys(dynamicData).forEach((key) => {
                pageTitle = pageTitle.replace(`{${key}}`, dynamicData[key]);
            });

            // Replace placeholders with params if dynamic data is not available
            Object.keys(params).forEach((key) => {
                pageTitle = pageTitle.replace(`{${key}}`, params[key]);
            });

            document.title = pageTitle;
            setPageTitle(pageTitle); // Update the pageTitle state
        } else {
            document.title = 'Default Title'; // Fallback
            setPageTitle('Default Title'); // Update the pageTitle state
        }
    }, [
        location.pathname,
        ...Object.values(dynamicData),
    ]);
};


export default usePageTitle;
