import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { makeAPIRequest } from 'API';
import Input from 'components/inputs/Input/Input';
import Button from 'components/buttons/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faUser, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import './Register.css'; // Import the CSS file for styling

const Register = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        password_confirmation: ''
    });
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [missingFields, setMissingFields] = useState([]);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleRegister = async () => {
        setMissingFields([]);
        try {
            const response = await makeAPIRequest('register', 'POST', formData);

            if (response) {
                const successMessage = response.status?.message || "Erfolgreich!";
                setSuccess(successMessage); // Optional: update local state if needed
                return Promise.resolve(successMessage);
            }
        } catch (err) {
            let errorMessage = '';
    
            if (err.response && err.response.status && err.response.status.validation_errors) {
                const fields = Object.keys(err.response.status.validation_errors);
                setMissingFields(fields);
                fields.forEach(element => {
                    errorMessage += `${err.response.status.validation_errors[element]}<br>`;
                });
                setError(errorMessage); // Optional: update local state if needed
            } else {
                errorMessage = err.message;
                setError(errorMessage); // Optional: update local state if needed
            }
    
            return Promise.reject(errorMessage); // Return the error message
        }
    };

    const handleCallBack = () => {
        setSuccess(null);
        navigate('/verify');
    };

    return (
        <div className="register">
            <div className="menu-container">

                <Link to={`/welcome`}>
                    <button className="back ra-btn icon-button">
                        <FontAwesomeIcon icon={faChevronLeft} size="xl" />
                    </button>
                </Link>
                <div className="menu">
                    <div className="row">
                        <div className="col-12">
                            <h1>Registrieren</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Input
                                label="Name"
                                name="name"
                                type="text"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Dein Name"
                                required
                                icon={<FontAwesomeIcon icon={faUser} />}
                                status={missingFields.includes('name') ? 'error' : ''}
                            />
                            <Input
                                label="E-Mail"
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Deine E-Mail-Adresse"
                                required
                                icon={<FontAwesomeIcon icon={faEnvelope} />}
                                status={missingFields.includes('email') ? 'error' : ''}
                            />
                            <Input
                                label="Passwort"
                                name="password"
                                type="password"
                                value={formData.password}
                                onChange={handleChange}
                                placeholder="Dein Passwort"
                                required
                                icon={<FontAwesomeIcon icon={faLock} />}
                                status={missingFields.includes('password') ? 'error' : ''}
                            />
                            <Input
                                label="Passwort bestätigen"
                                name="password_confirmation"
                                type="password"
                                value={formData.password_confirmation}
                                onChange={handleChange}
                                placeholder="Passwort bestätigen"
                                required
                                icon={<FontAwesomeIcon icon={faLock} />}
                                status={missingFields.includes('password') ? 'error' : ''}
                            />
                            <div className="mt-4"></div>
                            <Button 
                                label="Registrieren"
                                type="primary" 
                                width="100"
                                ghost={true}
                                errorMsg={error}
                                successMsg={success}
                                clickResponse={handleRegister}
                                callback={handleCallBack}
                            />
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <p>Bereits ein Konto? <Link to={`/login`}>Anmelden</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;

