import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import './ResendCode.css';

import { setUserToken, getUserToken, makeAPIRequest, setUserID } from 'API';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faChevronLeft, faEnvelope } from '@fortawesome/free-solid-svg-icons';

// Komponenten
import Input from 'components/inputs/Input/Input';
import Button from 'components/buttons/Button/Button';

function ResendCode() {
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [missingFields, setMissingFields] = useState([]);
    const navigate = useNavigate();


    const handleResendCode = async () => {
        setMissingFields([]);
        try {
            const response = await makeAPIRequest('register/resend/code', 'POST', { email });

            if (response) {
                const successMessage = response.status?.message || "Erfolgreich!";
                setSuccess(successMessage); // Optional: update local state if needed
                return Promise.resolve(successMessage); // Return the success message
            }
        } catch (err) {
            let errorMessage = '';

            if (err.response && err.response.status && err.response.status.validation_errors) {
                const fields = Object.keys(err.response.status.validation_errors);
                setMissingFields(fields);
                fields.forEach(element => {
                    errorMessage += `${err.response.status.validation_errors[element]}<br>`;
                });
                setError(errorMessage); // Optional: update local state if needed
            } else {
                errorMessage = err.message;
                setError(errorMessage); // Optional: update local state if needed
            }

            return Promise.reject(errorMessage); // Return the error message
        }
    };

    const handleCallBack = () => {
        setSuccess(null);
        navigate('/verify');
    };

    

    return (
        <div className="resend-code">
            <div className="menu-container">
                <Link to={`/welcome`}>
                    <button className="back ra-btn icon-button">
                        <FontAwesomeIcon icon={faChevronLeft} size="xl" />
                    </button>
                </Link>
                <div className="menu">
                    <div className="row">
                        <div className="col-12">
                            <h1>Code anfordern</h1>
                            <p>Bitte gib die E-Mail-Adresse ein, mit der du dich registriert hast.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Input
                                label="E-Mail-Adresse"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Deine E-Mail-Adresse"
                                required
                                icon={<FontAwesomeIcon icon={faEnvelope} />}
                            />
                            <div className="mt-4"></div>
                            <Button
                                label="Code anfordern"
                                type="primary" 
                                width="100"
                                ghost={true}
                                clickResponse={handleResendCode}
                                callback={handleCallBack}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResendCode;

