import React, { useContext } from 'react';

import './Profile.css';

//Hooks
import usePageTitle from 'hooks/usePageTitle';

// Components
import FeedItem from "components/feed/FeedItemNew/FeedItemNew";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faTwitter, 
    faInstagram, 
    faFacebook, 
    faGithub, 
    faLinkedin, 
    faYoutube,
    faTwitch
} from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons'; // Import fallback globe icon

import { UserContext } from 'contexts/UserContext';

function Profile() {
    const { user } = useContext(UserContext);

    usePageTitle({ display_name: user?.display_name || 'Lädt...' });

    return (
        <div className="profile px-3">
            <div className="row align-items-center mb-4">
                <div className="col">
                    <h1 className="displayname">{user.display_name}</h1>
                    <div className="username">@{user.name}</div>
                </div>
                <div className="col-auto">
                    <div className="user-img">
                        <img src={user.media?.small?.[1]} alt={user.name} />
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="user-bio mb-3">{user.bio}</div>
                <div className="user-stats d-flex">
                    <div className="stat">
                        {user.followers_count.toLocaleString()} Follower
                    </div>
                    <div className="stat">
                        {user.contents_count.toLocaleString() } Inhalte
                    </div>
                </div>
            </div>
            <div className="card">
            

                <div className="user-links d-flex">
                    {user.links?.map((link, index) => {
                        let icon = faGlobe; // Default to "globe" icon
                        let username = 'Unknown';
                        try {
                            const url = new URL(link);
                            const hostname = url.hostname.replace('www.', ''); // Remove "www." prefix
                            const domain = hostname.split('.')[0]; // Extract the base domain

                            // Define a mapping of domain names to Font Awesome icons
                            const iconMap = {
                                facebook: faFacebook,
                                twitter: faTwitter,
                                linkedin: faLinkedin,
                                github: faGithub,
                                instagram: faInstagram,
                                youtube: faYoutube,
                                twitch: faTwitch,
                                // Add more domain mappings here if needed
                            };

                            // Match the domain to an icon or fallback to "globe"
                            icon = iconMap[domain] || faGlobe;

                            // Extract username or path after the last /
                            username = link.substring(link.lastIndexOf('/') + 1) || 'Unknown';
                        } catch (error) {
                            console.error('Invalid URL:', link);
                        }

                        return (
                            <div className="link" key={index}>
                                <a href={link} target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={icon} /> {username}
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>


            <div>
                <h2>Inhalte</h2>
                {user.contents?.map((item, index) => (
                    <FeedItem key={item._id} item={item} />
                ))}

            </div>
        </div>
    );
}

export default Profile;
