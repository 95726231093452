import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { debouncedGet } from 'API';
import './Bubbles.css';

//Hooks
import usePageTitle from 'hooks/usePageTitle';

//Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

//Components
import Input from 'components/inputs/Input/Input';

function Bubbles() {
    const [bubbles, setBubbles] = useState([]); // Holds the fetched bubble data
    const [searchQuery, setSearchQuery] = useState(''); // Tracks the user's search input
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [error, setError] = useState(null); // Error state for API

    const endpoint = 'bubbles';

	usePageTitle('Bubbles');

    useEffect(() => {
        const fetchBubbles = async () => {
            setIsLoading(true);
            setError(null);

            try {
                // Build endpoint based on the search query
                const query = searchQuery
                    ? `?search=${encodeURIComponent(searchQuery)}`
                    : '';
                const data = await debouncedGet(`${endpoint}${query}`);
                setBubbles(data);
            } catch (err) {
                setError('Error fetching bubbles.');
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchBubbles();
    }, [searchQuery]); // Trigger API call on searchQuery change

	

    return (
        <div className="bubbles px-3 pt-3">
            {/* Search Input */}
            <div className="search-bar">
                <Input
                    label="Suche nach Bubbles"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Suche nach Bubbles"
                    icon={<FontAwesomeIcon icon={faSearch} />}
                />
            </div>

            {/* Show loaders while searching */}
            {isLoading && (
                <div className="bubble-list">
                    <div className="loader"></div>
                    <div className="loader"></div>
                    <div className="loader"></div>
                    <div className="loader"></div>
                    <div className="loader"></div>
                    <div className="loader"></div>
                </div>
            )}

            {/* Error Message */}
            {error && <p className="error">{error}</p>}

            {/* Show "Keine Bubbles gefunden." only when there are no results */}
            {!isLoading && bubbles.length === 0 && (
                <div className="row justify-content-center">
                    <div className="col-auto">
                        <span>Keine Bubbles gefunden.</span>
                    </div>
                </div>
            )}

            {/* Render bubbles */}
            <div className="bubble-list">
                <div className="row row-cols-1 row-cols-lg-2 g-3">
                    {!isLoading &&
                        bubbles.map((item) => (
                            <div className="col" key={item._id}>
                                <Link to={`/bubble/${item._id}`} className="no-deco">
                                    <div className="bubble-container h-100">
                                        {item.media?.small?.[1] && (
                                            <img
                                                src={item.media?.small?.[0]}
                                                alt={item.name}
                                                className="background"
                                            />
                                        )}
                                        <div className="bubble">
                                            {item.media?.small?.[0] && (
                                                <img
                                                    src={item.media?.small?.[0]}
                                                    alt={item.name}
                                                    className="bubble-image"
                                                />
                                            )}
                                            <div className="bubble-content">
                                                <h3
                                                    className="bubble-title crop-text-2"
                                                    title={item.name}>
                                                    {item.name}
                                                </h3>
                                                <p
                                                    className="bubble-description crop-text-3"
                                                    title={item.desc}>
                                                    {item.desc}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
}

export default Bubbles;
