import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import './Verify.css';

import { setUserToken, getUserToken, makeAPIRequest, setUserID } from 'API';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faChevronLeft, faEnvelope } from '@fortawesome/free-solid-svg-icons';

// Komponenten
import Input from 'components/inputs/Input/Input';
import Button from 'components/buttons/Button/Button';

function Verify({ onVerify }) {
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [initialLoad, setInitialLoad] = useState(true);
    const [missingFields, setMissingFields] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const verifyButtonRef = useRef(null);

    useEffect(() => {
        // Set token and email based on query parameters on page load
        const query = new URLSearchParams(location.search);
        const codeFromQuery = query.get('token');
        const emailFromQuery = query.get('email');

        if (codeFromQuery) setToken(codeFromQuery);
        if (emailFromQuery) setEmail(emailFromQuery);

        // Simulate button click if both token and email are set
        if (initialLoad && codeFromQuery && emailFromQuery && verifyButtonRef.current) {
            setInitialLoad(false);
            setTimeout(() => {
                verifyButtonRef.current.click();
            }, 500);
        }
    }, [location, initialLoad]);

    const handleVerify = async () => {
        setMissingFields([]);
        try {
            const response = await makeAPIRequest('register/verify', 'POST', { email, token });

            if (response) {
                const { token: userToken, user_id } = response.data;
                setUserToken(userToken);
                setUserID(user_id);
                const successMessage = response.status?.message || "Erfolgreich!";
                setSuccess(successMessage);
                return Promise.resolve(successMessage);
            }
        } catch (err) {
            let errorMessage = '';

            if (err.response && err.response.status && err.response.status.validation_errors) {
                const fields = Object.keys(err.response.status.validation_errors);
                setMissingFields(fields);
                fields.forEach(element => {
                    errorMessage += `${err.response.status.validation_errors[element]}<br>`;
                });
                setError(errorMessage);
            } else {
                errorMessage = err.message;
                setError(errorMessage);
            }

            return Promise.reject(errorMessage);
        }
    };

    const handleCallBack = () => {
        setSuccess(null);
        onVerify();
        navigate('/');
    };

    return (
        <div className="verify">
            <div className="menu-container">
                <Link to={`/welcome`}>
                    <button className="back ra-btn icon-button">
                        <FontAwesomeIcon icon={faChevronLeft} size="xl" />
                    </button>
                </Link>
                <div className="menu">
                    <div className="row">
                        <div className="col-12">
                            <h1>E-Mail verifizieren</h1>
                            <p>Bitte gib den Verifizierungscode ein, den wir dir per E-Mail gesendet haben.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Input
                                label="E-Mail-Adresse"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Deine E-Mail-Adresse"
                                required
                                icon={<FontAwesomeIcon icon={faEnvelope} />}
                            />
                            <Input
                                label="Verifizierungscode"
                                type="text"
                                value={token}
                                onChange={(e) => setToken(e.target.value)}
                                placeholder="Dein Verifizierungscode"
                                required
                                icon={<FontAwesomeIcon icon={faKey} />}
                            />
                            <div className="mt-4"></div>
                            <Button
                                ref={verifyButtonRef}
                                label="Verifizieren"
                                type="primary" 
                                width="100"
                                ghost={true}
                                clickResponse={handleVerify}
                                callback={handleCallBack}
                            />
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <p>Keinen Code erhalten? <Link to={`/resend`}>Code erneut senden</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Verify;
